import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import backgroundImg from '../img/city-never-sleeps.jpg'
import gladlyLogo from '../img/logo/gladly-logo.png'

const NotFoundPage = () => {
  // '#c76e0d' // old page orange
  const orangeColor = 'orange'
  return (
    <Layout>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        <div
          style={{
            backgroundImage: `url('${backgroundImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 120px inset',
            filter: 'saturate(.6) brightness(.45)',
            zIndex: -1,
          }}
        />
        <Link to="/">
          <img
            src={gladlyLogo}
            style={{
              position: 'absolute',
              top: 20,
              left: 20,
              width: 70,
              zIndex: 1,
            }}
          />
        </Link>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: '#FFF',
              marginBottom: 80,
              maxWidth: 700,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1 style={{ margin: 10 }}>Oops! This page doesn't exist.</h1>
            <Link
              to="/"
              style={{
                margin: 8,
                padding: 20,
                background: orangeColor,
                borderRadius: 3,
                boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 5px',
                textDecoration: 'none',
                color: '#FFF',
                textTransform: 'uppercase',
                fontWeight: 600,
              }}
            >
              Return home
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
